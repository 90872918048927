import { List, Map } from 'immutable'
import { TerminalType } from '@merchant/data/types'
import config from '@merchant/config'

export const initialAuthorizedSignatory = {
  birthdate: null,
  email: null,
  firstName: null,
  gender: null,
  lastName: null,
  nationality: config.DEFAULT_COUNTRY_CODE,
  phoneNumber: null,
  role: null,
}

type PaymentDetailsType = {
  accountHolder: string | null
  bankName: string | null
  bankClearingNumber: string | null
  iban: string | null
}

const newTerminal: TerminalType = {
  provider: null,
  id: null,
  unknownId: false,
}

const newPaymentDetails: PaymentDetailsType = {
  accountHolder: null,
  bankName: null,
  bankClearingNumber: null,
  iban: null,
}

const defaultData = {
  bankDetails: Map({}), // Quick IBAN: CH9709000000300097000 www.redcross.ch
  company: Map({}),

  contactPersonGender: undefined,
  contactPersonEmail: undefined,
  contactPersonFirstName: undefined,
  contactPersonLastName: undefined,
  contactPersonPhoneNumber: undefined,
  contactPersonNationality: undefined,
  contactPersonAddress: Map({}),
  contactPersonIsOwner: true,

  differentOwnerPersonGender: undefined,
  differentOwnerPersonEmail: undefined,
  differentOwnerPersonFirstName: undefined,
  differentOwnerPersonLastName: undefined,
  differentOwnerPersonPhoneNumber: undefined,
  differentOwnerPersonNationality: undefined,
  differentOwnerPersonAddress: Map({}),

  contractNumber: undefined,
  correspondenceLanguage: null,
  correspondenceRecipientPerson: Map({}),
  hasContract: false,
  hasDifferentCorrespondenceRecipient: false,
  locations: List(),
  mailPhone: Map({
    name: null,
    paymentDetails: Map(newPaymentDetails),
    requiresPhysicalTerminal: false,
    physicalTerminal: Map(newTerminal),
    requiresVirtualTerminal: false,
    virtualTerminal: Map(newTerminal),
    requiresPayByLink: false,
    payByLinkPaymentServiceProvider: null,
  }),
  ownershipChange: false,
  ownershipChangeDate: undefined,
  remote: Map({
    paymentDetails: Map(newPaymentDetails),
    paymentServiceProvider: undefined,
    webShops: List(),
  }),
  requiresLocal: undefined,
  requiresMailPhone: false,
  requiresPaymentNotice: false,
  requiresRemote: false,
  persons: Map(),
  pciAccepted: undefined,

  dataStorageConditionsAccepted: undefined,
  unknownContractNumber: false,
  documents: [],
  commissionPayoutFrequency: config.COMMISSION_PAYOUT_FREQUENCY,

  signingMode: 'individual', // NOTE: value preset to 'individual' signing mode
  authorizedSignatories: List(),
}

const initial: Map<string, any> = Map(defaultData)

export default initial
