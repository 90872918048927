export const CORRESPONDENCE_LANGUAGES = ['de', 'en', 'fr', 'it']
export const SUPPORTED_CORRESPONDENCE_LANGUAGES = ['de', 'fr']
export const GENDER = ['male', 'female']

export const TERMINAL_PROVIDER = [
  'six_wordline',
  'ccv',
  'concardis_ch',
  'concardis_de',
  'innocard',
  'bs_payone',
  'wallee',
  'adyen',
  'elavon',
  'other',
]

export const PAYMENT_SERVICE_PROVIDER = [
  'saferpay',
  'datatrans',
  'postfinance',
  'wallee',
  'concardis_payengine',
  'payone',
  'syspay',
  'computop',
  'braintree',
  'unzer',
  'sagepay',
  'cybersource',
  'checkout',
  'payzen',
  'elavon',
  'bnp_paribas',
  'other',
]

// NOTE: temporary location to centralize product data.
// Currently there is only a single product, but in the future we could have
// more.
export const PRODUCT = {
  name: 'American Express',
}

export default {
  AUTOCOMPLETE: {
    DEBOUNCE_TIME: 250, // in ms
    MIN_SEARCH_LENGTH: 2,
    MAX_SUGGESTIONS: 10,
  },
  COMMISSION_PAYOUT_FREQUENCY: 7, // in days.
  CONTACT_EMAIL: 'partner.service@swisscard.ch',
  CONTACT_PHONE: {
    TEXT: '+41 44 659 64 44',
    LINK: 'tel:+41446596444',
  },
  CONTACT_ADDRESS: {
    addressee: 'Swisscard AECS GmbH',
    department: 'American Express Merchant Service (JSOB5)',
    street: 'Neugasse 18',
    zip: '8810',
    city: 'Horgen',
  },
  DEFAULT_COUNTRY_CODE: 'CH',
  IDENTIFICATION_TERMS_CONDITIONS_URL: {
    de: 'https://www.swisscard.ch/en/technical-redirect/web/nutzungsbedingungen-fes-online-signatur/pdf', // FIXME: proper locale URL
    en: 'https://www.swisscard.ch/en/technical-redirect/web/nutzungsbedingungen-fes-online-signatur/pdf',
    fr: 'https://www.swisscard.ch/en/technical-redirect/web/nutzungsbedingungen-fes-online-signatur/pdf', // FIXME: proper locale URL
    it: 'https://www.swisscard.ch/en/technical-redirect/web/nutzungsbedingungen-fes-online-signatur/pdf', // FIXME: proper locale URL
  },
  IBAN_SEPARATOR: ' ',
  MAX_LOCATIONS: 4,
  MAX_SIGNING_PERSONS: 2,
  MAX_SIGNING_PERSONS_LIST_LENGTH: 10,
  MAX_TERMINALS_PER_LOCATION: 5,
  MAX_WEB_SHOPS: 3,
  OTP_TIMEOUT: 60 * 2, // in seconds
  PAYMENT_NOTICE_ACCOUNT_URL: 'http://www.partneronline.ch/',
  PHONE_NUMBER_INDICATIF: '+41',
  PHONE_NUMBER_INDICATIF_REGEX: /^\+41/,
  POLL_DELAY: 1500, // in ms, used for polling the contract document from the documents list, until the server generates it.
  DIS_REQUEST_POLL_DELAY: 6000, // in ms, used for polling the DIS (external) url.
  UPLOAD: {
    FILE_TYPES: ['image/gif', 'image/png', 'image/jpeg', 'image/jpg', 'application/pdf'],
    MAX_FILE_SIZE: 10 * 1024 * 1024,
    MAX_NUM_FILES: 3,
  },
  VALIDATION: {
    ACCOUNT_HOLDER_LENGTH: 50,
    CONTRACT_NUMBER_LENGTH: 10,
    EMAIL_LENGTH: 50,
    FIRSTNAME_LENGTH: 15,
    LASTNAME_LENGTH: 35,
    STREET_LENGTH: 35,
    ZIP_LENGTH: 7,
    CITY_LENGTH: 35,
    MAX_AGE: 100, // in years
    MIN_AGE: 18, // in years
    NAME_LENGTH: 50,
    COMPANY_NAME_LENGTH: 50,
    LOCATION_NAME_LENGTH: 50,
    MAIL_PHONE_NAME_LENGTH: 50,
    OWNERSHIP_CHANGE_DATE_THRESHOLD: 5, // in years
    BANK_NAME_LENGTH: 50,
    BANK_CLEARING_NUMBER_MIN_LENGTH: 3, // https://en.wikipedia.org/wiki/Bank_clearing_number
    BANK_CLEARING_NUMBER_MAX_LENGTH: 5,
    SIGNING_PERSON_NAME_LENGTH: 50,
    SIGNING_PERSON_ROLE_LENGTH: 20,
    TERMINAL_ID_LENGTH: 20,
    WEB_SHOP_URL_LENGTH: 60, // limited to 60 chars, so that it displays properly without being clipped in the merchant PDF contract placeholder
  },
} as const
